/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import 'colors';

$font-family-base: 'SF Pro Display', sans-serif;
$body-bg: $grey-1;
$body-color: $black;
$header-height: 92px;
$widgets-list-height: 220px;
$table-container-header-height: 96px;
