/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/colors";
@import "/src/assets/styles/mixins";

.card {
  padding: 1.5rem;
  height: calc(100% - 3rem);
}

.title {
  text-align: center;

  p {
    @include font(1.15rem, 1.3, 500, $grey-7);
  }
}

.paper {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    @include font(1rem, 1, 400, $black);
  }

  @media (max-width: 1200px) {
    display: block;
    text-align: center;

    div {
      margin-top: 0.5rem;
      justify-content: center;
    }
  }
}