/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import 'colors';

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin font($size: false, $lh: false, $weight: false, $color: false) {
  color: $black;
  @if $size {
    font-size: $size !important;
  }
  @if $lh {
    line-height: $lh !important;
  }
  @if $weight {
    font-weight: $weight !important;
  }
  @if $color {
    color: $color !important;
  }
}

@mixin setBgColorAndHover($baseColor) {
  background-color: $baseColor;
  &:hover {
    background-color: darken($baseColor, 5%);
  }
}

@mixin dividersForCells($color) {
  &:not(:first-child) {
    &:after {
      content: " ";
      position: absolute;
      border-left: 1px solid $color;
      top: 25%;
      left: 0;
      height: 50%;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
