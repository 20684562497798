/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/mixins";
@import "/src/assets/styles/variables";

.search {
  label {
    @include font(0.8rem, 1.25, 400, $grey-4);
  }

  input {
    @include font(0.9rem, 1.25, 400, $black);
  }
}