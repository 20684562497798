/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/mixins";
@import "/src/assets/styles/variables";

.paper {
  @include size(100%, fit-content);
}

.tableContainer {
  max-height: calc(100% - 52px);
}

.tableCellHeader {
  min-width: 170px;
  background-color: $grey-5 !important;
  border-bottom: 1px solid $grey-6 !important;
  @include font(0.875rem, 1.5rem, 500, $black);
  @include dividersForCells($grey-2);
}

.hoveredTableRow {
  &:hover {
    cursor: pointer !important;
    background-color: $light-blue-1 !important;

    td, div[data-inner-cell] {
      color: $blue-2 !important;
      @include dividersForCells($blue-2);
    }
  }
}

.tableCell {
  height: 1.24rem;
  padding: 0.99rem 1rem !important;
  position: relative;
}

.innerTableCell {
  width: 100%;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block !important;
  padding: 0.99rem 1rem !important;
  position: relative;
}
