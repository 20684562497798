/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/variables";
@import "/src/assets/styles/mixins";

.content {
  display: flex;
  padding: 0 1.5rem;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.title {
  text-align: center;
  margin-bottom: 1rem;

  p {
    @include font(1.15rem, 1.3, 500, $grey-7);
  }
}

.actions {
  display: flex;
  justify-content: center !important;
  padding: 1.5rem 1rem 0.75rem !important;

  button {
    min-width: 200px;
  }
}