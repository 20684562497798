/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import 'variables';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

html,
body {
  height: 100%;
}

body {
  font-family: $font-family-base !important;
  background-color: $body-bg;
  color: $body-color !important;
  padding: 0 !important;
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  text-decoration: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  outline: none;
  background-color: transparent;
}

a {
  cursor: pointer;
}

p {
  margin: 0;
}
