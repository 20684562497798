/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

// flexbox
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-align-center {
  display: flex;
  align-items: center;
}