/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/variables";
@import "/src/assets/styles/mixins";

.pageContainer {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1.25rem;
  height: calc(100% - #{$header-height} - 1.5rem);

  & > div {
    &:first-child {
      display: flex;
      flex: 1 1 30%;
    }

    &:last-child {
      display: flex;
      flex: 1 1 70%;
    }
  }
}

.firstBlocks {
  display: flex;
  column-gap: 12px;

  & > div {
    @include size(50%, 100%);
  }
}