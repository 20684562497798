/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import 'colors';

// primary
$primary-main: #1a97f5;
$primary-light: rgba(26, 151, 245, 0.1);
$primary-dark: #569cca;

:export {
  primaryMain: $primary-main;
  primaryLight: $primary-light;
  primaryDark: $primary-dark;
}
