/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/mixins";
@import "/src/assets/styles/variables";

.tableContainer {
  margin-top: 0.75rem;
  @include size(100%, fit-content);

  & > div {
    width: 100%;
    height: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  height: $table-container-header-height;
}

.tabs {
  display: flex;
  align-items: center;

  hr {
    @include size(1px, 36px);
    background-color: $grey-2;
    border: none;
  }
}

.tab {
  align-items: center;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0.5rem;
  max-width: 120px;
  margin: 0 0.5rem;

  & > div {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  svg {
    @include size(31px);
  }

  p {
    margin-top: 0.3rem;
    transition: color .1s;
    text-align: center;

    @include font(0.75rem, 1, 600, $grey-3);
  }

  &:hover {
    p {
      color: $blue-1 !important;
    }
  }
}

.tabActive {
  cursor: default !important;

  p {
    color: $blue-1 !important;
  }
}

.title {
  display: flex;
  align-items: center;

  p {
    @include font(1.25rem, 1.25, 600, $grey-3);
    margin-right: 0.5rem;
  }

  span {
    @include font(1rem, 1, 600, $blue-1);
    margin-top: 3px;
  }
}