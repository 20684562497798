/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/mixins";
@import "/src/assets/styles/variables";

@mixin secondaryBase($borderType, $borderColor: false) {
  background-color: transparent;
  color: $grey-3;
  border: 1px $borderType $blue-1;
  &:hover {
    background-color: darken($white, 5%);
  }

  @if $borderColor {
    border-color: $borderColor;
  }
}

.button {
  padding: 0.5rem 0.75rem;
  border-radius: 24px 0 18px 24px;
  min-width: 170px;
  @include font(1rem, 1.2, 500);
  transition: background-color .1s;
  white-space: nowrap;
}

.withStartIcon {
  display: flex;
  align-items: center;

  svg {
    margin: 2px;
    @include size(30px);
  }

  & > div {
    flex-grow: 1;
  }
}

.primary {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  background-color: $blue-1;
  color: $white;
  @include setBgColorAndHover($blue-1);
}

.secondary {
  @include secondaryBase(solid);
}

.third {
  @include secondaryBase(dashed);
}

.exception {
  @include secondaryBase(solid, $grey-3);
  padding: 0.5rem 0.5rem;
}

.exception1 {
  @include secondaryBase(solid);
  padding: 0.25rem 5px;
  min-width: 155px;

  svg {
    margin-top: -1px;
    @include size(24px);
  }
}

.icon {
  min-width: 0;
  width: fit-content;
  border: 1px solid $grey-2;
  border-radius: 5px;
  @include setBgColorAndHover($white);
  padding: 0;
}

.disabled {
  cursor: default;
  background-color: $grey-5;
  color: $black-1;

  &:hover {
    background-color: $grey-5;
  }
}