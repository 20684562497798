/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/colors";
@import "/src/assets/styles/mixins";

.card {
  padding: 1.5rem;
  height: calc(100% - 3rem);

  hr {
    margin: 1rem 0;
    background-color: $grey-5;
  }
}

.userLogoIcon {
  @include size(94px);

  img {
    @include size(inherit);
  }
}

.userInfo {
  margin-left: 2.5rem;

  h6 {
    @include font(1.25rem, 1.5, 700, $black-1);
  }

  p {
    @include font(1rem, 1.5, 400, $grey-10);
  }
}

.actionsBlock {
  display: flex;
  justify-content: center;
  flex-grow: 1;

  & > div {
    @include size(52px);
    border: 1px solid $grey-2;
    border-radius: 5px;
    display: grid;
    place-items: center;

    &:not(:first-child) {
      margin-left: 0.5rem;
    }

    button {
      @include size(100%);
      transition: background-color .15s;

      svg {
        transition: fill .15s;
      }

      &:hover {
        background-color: $blue-1;

        svg {
          fill: $white;
        }
      }
    }
  }
}

.companyCardTitle {
  margin-bottom: 0.5rem;

  p {
    @include font(1rem, 1, 400, $black);
  }
}

.companyCardList {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 1.5rem;

  span {
    @include font(0.85rem, 1.3, 500, $grey-11);
  }

  p {
    @include font(1rem, 1.3, 500, $black-1);
  }
}