/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

.financing-diagram {
    height: 300px;
    width: 47vw;
    cursor: default !important;
}

li::before {
    content: none !important;
}