/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/variables";
@import "/src/assets/styles/mixins";

.card {
  padding: 1.5rem;
  height: calc(100% - 3rem);
}

.cardIsContractor {
  width: 100%;
  height: fit-content;
}

.title {
  text-align: center;
  p {
    @include font(1.15rem, 1.3, 500, $grey-7);
  }
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

  button {
    min-width: 200px;
  }
}

.fileInfo {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 1.25rem;

  p {
    @include font(0.9rem, 1.2, 400, $grey-7);
    margin-left: 0.75rem;
  }

  svg {
    @include size(24px, 33px);
    margin-top: -3px;
  }
}