/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

$white: #ffffff;
$black: #000000;
$black-1: #252525;
$grey-1: #fafbfb;
$grey-2: #C4C4C4;
$grey-3: #676767;
$grey-4: #BCBCBC;
$grey-5: #E6E6E6;
$grey-6: #9C9C9C;
$grey-7: #444444;
$grey-8: #ABABAB;
$grey-9: #838383;
$grey-10: #555555;
$grey-11: #7F8690;
$light-blue-1: rgba(26, 151, 245, 0.1);
$blue-1: #569CCA;
$blue-2: #1A97F5;
$green-1: #2EAE67;
$green-2: #39851E;
