/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/mixins";
@import "/src/assets/styles/colors";

.title {
  @include font(1.1rem, 1.2, 500, $grey-7);
  text-align: center;
  margin: 0;
  padding: 1.25rem 1rem 1.5rem 1rem !important;

  button {
    @include size(32px);
    padding: 0.5rem;

    svg {
      @include size(20px);
    }
  }
}