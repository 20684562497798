/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/colors";
@import "/src/assets/styles/mixins";

@mixin customStepBorder($isComplete: false) {
  border: 2px dashed $grey-2;
  border-radius: 50%;

  @if $isComplete {
    border-style: solid;
    border-radius: 0.3rem 0.3rem 1.7rem 0.3rem;
  }
}

@mixin customTitleBorder($isComplete: false) {
  border: 1px dashed $blue-1;
  border-radius: 0.3rem 0.3rem 1.7rem 0.3rem;

  @if $isComplete {
    border-style: solid;
    border-color: $green-1;
  }
}

.card {
  padding: 2.5rem 0 2rem 3rem;
  margin-bottom: 1rem;
}

.title {
  margin-top: auto;
  margin-bottom: auto;

  p {
    @include font(1.25rem, 1.2, 500, $grey-7);
  }
}

.list {
  display: flex;
  overflow-x: auto;
  padding-bottom: 1rem;

  & > div:not(:first-child) {
    margin-left: 0.25rem;
  }
}

.stepContainer {
  @include size(74px);
  @include customStepBorder();
  display: grid;
  place-items: center;

  span {
    @include font(1.5rem, 1.15, 700, $blue-1);
  }

  p {
    @include font(1.1rem, 1, 500, $grey-7);
  }
}

.stepContainerComplete {
  @include customStepBorder(true);

  span {
    color: $green-1 !important;
  }

  p {
    color: $black !important;
  }
}

.stepIcon {
  position: absolute;
  top: 0;
  right: 0;
}

.stepTitle {
  @include customTitleBorder();
  @include size(175px, 76px);
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  margin-top: 0.75rem;

  p {
    @include font(1rem, 1.2, 500, $grey-7);
  }
}

.stepTitleComplete {
  @include customTitleBorder(true);
}