/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFPro/SFProDisplay-Regular.eot');
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
  url('../fonts/SFPro/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SFPro/SFProDisplay-Regular.woff2') format('woff2'),
  url('../fonts/SFPro/SFProDisplay-Regular.woff') format('woff'),
  url('../fonts/SFPro/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFPro/SFProDisplay-Medium.eot');
  src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
  url('../fonts/SFPro/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SFPro/SFProDisplay-Medium.woff2') format('woff2'),
  url('../fonts/SFPro/SFProDisplay-Medium.woff') format('woff'),
  url('../fonts/SFPro/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFPro/SFProDisplay-Bold.eot');
  src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
  url('../fonts/SFPro/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SFPro/SFProDisplay-Bold.woff2') format('woff2'),
  url('../fonts/SFPro/SFProDisplay-Bold.woff') format('woff'),
  url('../fonts/SFPro/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
