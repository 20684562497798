/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/variables";
@import "/src/assets/styles/mixins";

.header {
  padding-top: 24px;
  display: flex;
  margin-left: 46.5%;
  margin-right: 1.25rem;
  justify-content: space-between;
  align-items: center;

  p {
    @include font(1.5rem, 1.3, 300, $grey-7);
  }
}

.headerItemsIsFound {
  margin-left: 1.25rem;
  justify-content: flex-start;

  & > div {
    flex: 1 1 30%;

    &:nth-child(2) {
      flex: 1 1 40%;
      text-align: center;
    }

    &:nth-child(3) {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.card {
  margin-top: 1.5rem;
  height: fit-content;
  width: 100%;
  margin-bottom: 1.5rem;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

  button {
    min-width: 200px;
  }
}

.fileInfo {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 1.25rem;
  margin-left: 1.25rem;

  p {
    @include font(0.9rem, 1.2, 400, $grey-7);
    margin-left: 0.75rem;
  }

  svg {
    @include size(24px, 33px);
    margin-top: -3px;
  }
}

.fileActions {
  button {
    @include size(26px);
  }
}