/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/mixins";
@import "/src/assets/styles/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1.25rem;
  height: $header-height;
}

.card {
  flex-basis: 35%;
  padding: 1rem 2rem;
  border-radius: 0 0 2rem 0 !important;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.logo {
  @include size(140px, 60px);

  svg {
    @include size(inherit);
  }
}

.notificationIcon {
  @include size(35px, 40px);

  svg {
    @include size(inherit);
  }
}

.userLogoIcon {
  @include size(55px, 50px);

  img {
    @include size(inherit);
  }
}

.userRole {
  display: flex;
  align-items: center;
  margin-left: 4rem;
  hr {
    @include size(1px, 36px);
    background-color: $grey-2;
    margin-right: 0.75rem;
  }

  p {
    @include font(1.25rem, 1.5, 600, $grey-3)
  }
}