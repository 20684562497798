/*!
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

@import "/src/assets/styles/mixins";
@import "/src/assets/styles/variables";

.content {
  display: flex;

  & > div {
    width: 100%;
  }
}

.actions {
  justify-content: center !important;
  padding: 1.5rem 1rem 0.75rem !important;

  button {
    width: 200px;
  }
}

.selectFileContainer {
  display: flex;
  padding: 0 3rem 0;

  input {
    display: none;
  }
}

.selectFileInfo {
  display: flex;
  align-items: center;
  border: 1px solid rgba(156, 156, 156, 0.4);
  border-radius: 0 0 1.5rem 0;
  flex-grow: 1;
  padding: 0.5rem 1rem;
  margin-right: 1.25rem;

  p {
    @include font(0.9rem, 1.2, 400, $grey-7);
    margin-left: 0.5rem;
  }

  svg {
    @include size(24px, 33px);
    margin-top: -3px;
  }
}

.selectFileBtn {
  padding: 0.5rem 0.75rem;
  border-radius: 24px 0 18px 24px;
  min-width: 130px;
  @include font(1rem, 1.2, 500);
  transition: background-color .1s;
  white-space: nowrap;
  text-align: center;

  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-top: -1px;
    @include size(30px);
  }

  & > div {
    flex-grow: 1;
  }

  background-color: transparent;
  color: $grey-3;
  border: 1px solid $blue-1;

  &:hover {
    background-color: darken($white, 5%);
  }
}